import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import ControlledCarousel from '../components/ControlledCarousel';

const CarouselLeftControlledTextRightSection = ({ items }) => {
  const [selectedCarouselItem, setSelectedCarouselItem] = useState(0);
  const handleSelect = (selectedIndex) => {
    setSelectedCarouselItem(selectedIndex);
  };

  return (
    <Container className="py-5 my-0 bg-primary" fluid>
      <Container>
        <Row className="justify-content-between" noGutters>
          <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 8 } xl={ 8 } className="my-4">
            <ControlledCarousel items={ items } activeIndex={ selectedCarouselItem } selectHandler={ handleSelect } showCTA />
          </Col>
          <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 3 } xl={ 3 } style={{ borderTop: '2px solid white' }} className="pt-1 my-4">
            <h3 className="text-white text-break">{items[selectedCarouselItem].title}</h3>
            <p className="text-white">{items[selectedCarouselItem].description}</p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

CarouselLeftControlledTextRightSection.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    shortDescription: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.objectOf(PropTypes.object),
    actionTitle: PropTypes.string,
    route: PropTypes.string
  })).isRequired
};

export default CarouselLeftControlledTextRightSection;