import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Layout from '../components/Layout';
import { HTMLContent } from '../components/MarkdownContent';
import CarouselLeftControlledTextRightSection from '../sections/CarouselLeftControlledTextRightSection';
import FormCardSection from '../sections/FormCardSection';
import HeroSection from '../sections/HeroSection';
import IntroWithCountersSection from '../sections/IntroWithCountersSection';
import TextImageSection from '../sections/TextImageSection';

export const IndexPageTemplate = ({
  hero,
  intro,
  solutions,
  technologies,
  form,
  html,
  contentComponent
}) => (
  <div>
    <HeroSection
      title={ hero.title }
      subtitle={ hero.subtitle }
      heightStyle="60vh"
      image={ hero.image }
      actionTitle={ hero.actionTitle }
      actionRoute={ hero.route }
      hasCTA
      className="my-0"
    />
    <IntroWithCountersSection
      title={ intro.title }
      description={ intro.description }
      fundingIndications={ intro.fundingIndications }
      numbers={ intro.numbers }
    />
    <CarouselLeftControlledTextRightSection items={ solutions } />
    <TextImageSection
      title={ technologies.title }
      description={ technologies.description }
      image={ technologies.image }
    />
    <FormCardSection
      name="home"
      title={ form.title }
      fieldLabels={ form.fieldLabels }
      dataProtectionLabel={ html }
      actionTitle={ form.actionTitle }
      backgroundImage={ form.backgroundImage }
      contentComponent={ contentComponent }
    />
  </div>
);

IndexPageTemplate.propTypes = {
  hero: PropTypes.shape({
    image: PropTypes.objectOf(PropTypes.object),
    title: PropTypes.string,
    subtitle: PropTypes.string,
    actionTitle: PropTypes.string,
    route: PropTypes.string
  }).isRequired,
  intro: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    fundingIndications: PropTypes.arrayOf(PropTypes.shape({
      image: PropTypes.objectOf(PropTypes.object),
      url: PropTypes.string
    })),
    numbers: PropTypes.arrayOf(PropTypes.shape({
      number: PropTypes.number,
      text: PropTypes.string
    }))
  }).isRequired,
  solutions: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    shortDescription: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.objectOf(PropTypes.object),
    actionTitle: PropTypes.string,
    route: PropTypes.string,
    bannerText: PropTypes.string
  })).isRequired,
  technologies: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.objectOf(PropTypes.object)
  }).isRequired,
  form: PropTypes.shape({
    title: PropTypes.string,
    fieldLabels: PropTypes.arrayOf(PropTypes.string),
    actionTitle: PropTypes.string,
    backgroundImage: PropTypes.objectOf(PropTypes.object)
  }).isRequired,
  html: PropTypes.node.isRequired,
  contentComponent: PropTypes.func
};

IndexPageTemplate.defaultProps = {
  contentComponent: null
};

const IndexPage = ({ data }) => {
  const { frontmatter, html, fields } = data.markdownRemark;

  return (
    <Layout
      title={ frontmatter.title }
      description={ frontmatter.intro ? frontmatter.intro.description : '' }
      slug={ fields.slug }
      language={ frontmatter.language }
    >
      <IndexPageTemplate
        hero={ frontmatter.hero }
        intro={ frontmatter.intro }
        solutions={ frontmatter.solutions }
        technologies={ frontmatter.technologies }
        form={ frontmatter.form }
        html={ html }
        contentComponent={ HTMLContent }
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      html: PropTypes.node,
      fields: PropTypes.object
    }),
  }).isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexById($id: String!, $language: String!) {
    markdownRemark(id: { eq: $id }, frontmatter: { templateKey: { eq: "index-page" }, language: { eq: $language } }) {
      fields {
        slug
      }
      html
      frontmatter {
        language
        title
        hero {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          title
          subtitle
          actionTitle
          route
        }
        intro {
          title
          description
          fundingIndications {
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            url
          }
          numbers {
            number
            text
          }
        }
        solutions {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 1048, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          shortDescription
          actionTitle
          route
          bannerText
        }
        technologies {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        form {
          title
          fieldLabels
          actionTitle
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`;